<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" src="../assets/static/prodetail_bg.png"/>
			<div class="title">
				<div class="fontsize48 colorwhite">项目资源</div>
				<div class="desc fontsize18 colorwhite">{{typeName || ""}}</div>
			</div>
		</div>
		<div class="block30"></div>
		<div class="huaxue_prodock_detail relative w1239" v-if="dataobj">
			<div class="data_con">
				<div class="left">
					<div class="titlename fontsize30">{{dataobj.title}}</div>
					<div class="descbox fontsize18">
						<div class="text">行业分类：<span>{{dataobj.industryType}}</span></div>
						<div class="text" v-if="dataobj.successFlag==1">需求类型：<span>{{dataobj.demandType}}</span></div>
						<div class="text" v-if="dataobj.successFlag==1">截止时间：<span>{{dataobj.endDate}}</span></div>
						<div class="text" v-if="dataobj.successFlag==1">所在地：<span>{{dataobj.province}}{{dataobj.city}}</span></div>
						<div class="text" v-if="dataobj.successFlag==0">技术类型：<span>{{dataobj.technologyType}}</span></div>
						<div class="text" v-if="dataobj.successFlag==0">成熟度：<span>{{dataobj.maturity}}</span></div>
						<div class="text" v-if="dataobj.successFlag==0">交易方式：<span>{{dataobj.payType}}</span></div>
					</div>
					<div class="con_box fontsize16">
						<div v-html="dataobj.resourceBody"></div>
					</div>
				</div>
				<div class="wblock20"></div>
				<div class="right">
					<!-- <img v-if="dataobj.imgUrl" :src="dataobj.imgUrl || require('../assets/static/noimg.png')"/> -->
					<div class="imgdiv" v-for="(item,index) in dataobj.imglist" :key="index">
						<img :src="item || require('../assets/static/noimg.png')"/>
					</div>
				</div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			typeName:"",
			uid:0,//详情
			dataobj:null,
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			if(this.$route.query.typeName){
				this.typeName = this.$route.query.typeName
			}
			this.getDataDetail()
		}else{
			this.$util.routerPath("/404")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取详情
		getDataDetail() {
			var _this = this;
			var params = {
				id:this.uid
			};
			this.$http.post('frontEnd/ProjectResource/getProjectResourceByID', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						dataobj["imglist"] = []
						if(dataobj.imagesList&&dataobj.imagesList!='[]'){
							try{
								dataobj["imgUrl"] = JSON.parse(dataobj.imagesList)[0]
								dataobj["imglist"] = JSON.parse(dataobj.imagesList)
							}catch(e){
								//TODO handle the exception
							}
						}
						_this.dataobj = dataobj
					}else{
						_this.$util.routerPath("/404")
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
